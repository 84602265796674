import React from "react";
import InfoContactUsForm from "../../components/info-contact-us-form";
import Layout from "../../components/layout";
import Quote from "../../components/quote";
import {navigate} from "gatsby";

const subscriberBuyerPersonaExamples =  process.env.GATSBY_SUBSCRIBER_LP_BUYER_PERSONA_EXAMPLES_FORM_TAG;


const Main = ({location}) => (
  <section className="container-xl p-0">
    <div className="row m-auto">
      <div className="col-12 col-md-6 py-5 pr-md-5 py-md-6">
        <h3>Free Buyer Personas Examples</h3>
        <h1>
          Make Inbound Marketing Work for You: Grab your FREE Buyer Personas
          Examples
        </h1>
        <p>
          Buyer personas are an important part of an inbound marketing strategy
          and can help you navigate new potential customers as well as existing
          and long term clientele.
          <br />
        </p>
        <p>Remember:</p>
        <ul className="list-unstyled d-flex flex-wrap">
          <li className="d-flex align-items-center mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon flex-shrink-0 mr-4"
              width="46"
              height="46"
              viewBox="0 0 45.414 38"
            >
              <g
                fill="none"
                stroke-linecap="square"
                stroke-miterlimit="10"
                stroke-width="1"
                transform="translate(-1 -5)"
              >
                <path stroke="#92c954" d="M16 18H2M16 30H2M32 42H2M32 6H2" />
                <circle
                  cx="8"
                  cy="8"
                  r="8"
                  stroke="currentColor"
                  transform="translate(24 16)"
                />
                <path stroke="currentColor" d="m45 37-7.344-7.344" />
              </g>
            </svg>
            <p>Do your research</p>
          </li>
          <li className="d-flex align-items-center mb-3">
            <svg
              className="icon flex-shrink-0 mr-4"
              id="b571dc4d-d14c-4f83-a804-3863a81da0be"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              width="46"
              height="46"
              viewBox="0 0 144 144"
            >
              <g id="abd4ad87-a2ed-45f2-bbf6-98aba4eca839" data-name="n-check">
                <path
                  id="f011f813-6dfa-4694-94cb-c93bc4ba4691"
                  data-name="Path 403"
                  d="M116.7,53.71A52.8,52.8,0,1,1,67.94,21.2a53.4,53.4,0,0,1,28.24,8.13"
                  fill="none"
                  stroke="currentColor"
                  stroke-miterlimit="10"
                  stroke-width="3"
                />
                <path
                  id="a43fd21c-5dc8-491d-ae36-dcd02932ef2e"
                  data-name="Path 404"
                  d="M47.63,57.77,67.94,78.09l60.95-60.95"
                  fill="none"
                  stroke="#76bc21"
                  stroke-linecap="square"
                  stroke-miterlimit="10"
                  stroke-width="3"
                />
              </g>
            </svg>
            <p>Make each user persona in-depth and well rounded</p>
          </li>
          <li className="d-flex align-items-center mb-3">
            <svg
              className="icon flex-shrink-0 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              width="46"
              height="46"
              viewBox="0 0 46 46"
            >
              <g id="b-meeting" transform="translate(-1 -1)">
                <g id="Group_245" data-name="Group 245">
                  <path
                    id="Path_691"
                    data-name="Path 691"
                    d="M46,2H22V23l9-7H46Z"
                    fill="none"
                    stroke="#76bc21"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                  <path
                    id="Path_692"
                    data-name="Path 692"
                    d="M20,43.672a3.95,3.95,0,0,0-1.476-3.108A11.951,11.951,0,0,0,11,38a11.951,11.951,0,0,0-7.524,2.564A3.95,3.95,0,0,0,2,43.672V46H20Z"
                    fill="none"
                    stroke="#111"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                  <circle
                    id="Ellipse_28"
                    data-name="Ellipse 28"
                    cx="5"
                    cy="5"
                    r="5"
                    transform="translate(6 23)"
                    fill="none"
                    stroke="#111"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                  <path
                    id="Path_693"
                    data-name="Path 693"
                    d="M46,43.672a3.95,3.95,0,0,0-1.476-3.108,12.322,12.322,0,0,0-15.048,0A3.95,3.95,0,0,0,28,43.672V46H46Z"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                  <circle
                    id="Ellipse_29"
                    data-name="Ellipse 29"
                    cx="5"
                    cy="5"
                    r="5"
                    transform="translate(32 23)"
                    fill="none"
                    stroke="#111"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                </g>
              </g>
            </svg>
            <p>Reach out to existing customers if need be</p>
          </li>
        </ul>
      </div>
      <div className="col-12 col-md-6 bg-primary py-6 px-lg-6">
        <h3 className="text-center text-white">
          Getting started with Inbound Marketing?
        </h3>
        <h4 className="text-center">
          Check out these example buyer personas to help get you started.
        </h4>
        <div className="bg-light p-4 bg-light mt-3">
          <InfoContactUsForm
            location={location}
            onSubmitSuccess={()=> navigate('/lp/ty-buyer-personas-examples')}
            tags={[subscriberBuyerPersonaExamples]}
            buttonText="GET AWS CHEAT SHEET" />
        </div>
      </div>
    </div>
  </section>
);

const Testimonial = () => (
  <section className="py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 align-self-center">
          <h2>
            What our clients are saying regarding our Inbound Marketing
            Strategies
          </h2>
        </div>
        <div className="col-12 col-md-6">
          <Quote
            quote="729 Solutions has an excellent team - I am hoping our paths will cross again!"
            name="NOOPUR SHANKAR"
            company="Optimal Strategix Group, Inc."
          />
        </div>
      </div>
    </div>
  </section>
);

const Index = ({location}) => (
  <Layout>
    <Main location={location}/>
    <Testimonial />
  </Layout>
);

export default Index;
